.videoContainer {
    position: relative;
    width: 100%;
    
    max-width: 100vw;
    background: black;
    overflow: hidden;

    @media (max-width: 1023px) {
        height: calc(100vh - 100px);
    }

    & video {
        /* height: 550px !important; */
    }
    & .videoThumbnail{
        height: 100%;
        & img{
            object-fit: contain;
        }
    }
    /* & button {
        width: max-content;
        padding: 0;
        &:before {
            content: none;
        }

        &:hover {
            background: transparent;
        }
        &:focus {
            background: transparent !important;
            border: none;
        }

    } */
}

.controls {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 16px;
    background: var(--Slate, #171819);
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 7;
}

.episodeInfo {
    color: #FFF;
    font-size: 22px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;

    /* 109.091% */
    @media (max-width: 1023px) {
        display: none;
    }
}

.mainColumn {
    overflow: hidden;
}

.controlButtons {
    display: flex;
    gap: 16px;
    align-items: center;
    position: absolute;
    left: 0;
    right: 0;
    justify-content: center;
}

.button {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 18px;
    padding: 8px;
    position: relative;

    &:hover {
        background-color: transparent;
        background: transparent;
    }
}


.playButton {
    font-size: 24px;
    height: 60px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    & :global(.playingButton) {
        height: 68px;
        min-width: 96px;
        margin-top: 20px;
    }
}

.rightControls {
    display: flex;
    gap: 16px;
    z-index: 7;
}

.videoPlayer {
    position: relative;
}

.playIcon {
    cursor: pointer;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.volumeControl {
    position: relative;
    display: flex;
    align-items: center;
}

.volumeSliderContainer {
    position: absolute;
    bottom: 80%;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(0, 0, 0, 0.8);
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 10px;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.volumeSlider {
    writing-mode: bt-lr;
    -webkit-appearance: slider-vertical;
    width: 8px;
    height: 80px;
    padding: 0 0px;
    margin: 0 auto;
    /* Center the slider */
    background: transparent;
    /* Remove default background */
}

.volumeSlider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 16px;
    height: 16px;
    background: white;
    border-radius: 50%;
    cursor: pointer;
    margin-right: -4px;
    /* Center the thumb by offsetting half the difference between thumb and track width */
}

.volumeSlider::-webkit-slider-runnable-track {
    width: 8px;
    height: 100%;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 4px;
    cursor: pointer;
}

/* For Firefox */
.volumeSlider::-moz-range-thumb {
    width: 16px;
    height: 16px;
    background: white;
    border-radius: 50%;
    cursor: pointer;
    border: none;
    transform: translateX(-4px);
    /* Center the thumb in Firefox */
}

.volumeSlider::-moz-range-track {
    width: 8px;
    height: 100%;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 4px;
    cursor: pointer;
}

/* Remove default focus styles */
.volumeSlider:focus {
    outline: none;
}

.volumeSlider::-moz-focus-outer {
    border: 0;
}

/* Add fullscreen styles */
.videoContainer:fullscreen {
    width: 100vw;
    height: 100vh;
}

/* Webkit support */
.videoContainer:-webkit-full-screen {
    width: 100vw;
    height: 100vh;
}

/* Firefox support */
.videoContainer:-moz-full-screen {
    width: 100vw;
    height: 100vh;
}

/* MS support */
.videoContainer:-ms-fullscreen {
    width: 100vw;
    height: 100vh;
}

.progressContainer {
    position: absolute;
    top: -10px;
    left: 0;
    right: 0;
    width: 100%;
    height: 10px;
    background: #343434;
    cursor: pointer;
    overflow: hidden;
}

.progressBar {
    -webkit-appearance: none;
    width: 100%;
    height: 10px;
    background: transparent;
    cursor: pointer;
    border: 0;
    padding: 0;
}

/* Progress bar track */
.progressBar::-webkit-slider-runnable-track {
    width: 100%;
    height: 10px;
    background: rgba(255, 255, 255, 0.1);
    border: none;
    border-radius: 0;
}

.progressBar::-moz-range-track {
    width: 100%;
    height: 10px;
    background: rgba(255, 255, 255, 0.1);
    border: none;
    border-radius: 0;
}

/* Progress bar fill */
.progressBar::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 10px;
    width: 0;
    background: #D6D6D6;
    cursor: pointer;
    border: none;
    border-radius: 0;
    box-shadow: -100vw 0 0 100vw #D6D6D6;
}

.progressBar::-moz-range-thumb {
    height: 10px;
    width: 0;
    background: #D6D6D6;
    cursor: pointer;
    border: none;
    border-radius: 0;
    box-shadow: -100vw 0 0 100vw #D6D6D6;
}

/* Hide focus outline */
.progressBar:focus {
    outline: none !important;
    background-color: #343434;
    box-shadow: none !important;
}

/* Optional: Add hover effect */
.progressContainer:hover .progressBar::-webkit-slider-thumb {
    height: 12px;
    margin-top: -1px;
}

.progressContainer:hover .progressBar::-moz-range-thumb {
    height: 12px;
    margin-top: -1px;
}

.mobileMenuButton {
    display: none;
}

.controlsWrapper {
    display: flex;
    align-items: center;
    gap: 8px;
}

@media (max-width: 1023px) {
    .rightControls {
        z-index: 12;
    }

    .controls {
        justify-content: flex-end;
    }

    .mobileMenuButton {
        display: block;
    }

    .controlsWrapper {
        display: none;
        position: absolute;
        bottom: 100%;
        right: 0;
        background: rgba(0, 0, 0, 0.8);
        padding: 12px;
        border-radius: 8px;
        flex-direction: column;
    }

    .controlsWrapper.showMobile {
        display: flex;
    }

    /* Adjust volume slider for mobile */
    .volumeControl {
        /* width: 100%; */
    }

    .volumeSliderContainer {
        position: relative;
        width: 100%;
        margin-top: 8px;
    }
}

.episodeButtonActive {
    & path {
        stroke: #FF0000;
    }
}

.commentButtonActive {
    & path {
        fill: #FF0000;
    }
}

.subtitles {
    display: none;
    position: absolute;
    bottom: 60%;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.14), 0px 3px 14px 0px rgba(0, 0, 0, 0.12),
        0px 5px 5px 0px rgba(0, 0, 0, 0.2);
    z-index: 4;

    & li {
        color: var(--colorBlack);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        /* 142.857% */
        letter-spacing: 0.25px;
        text-align: left;
        padding-left: 10px;
    }
}

.subtitles.show {
    display: block;
    padding: 2px 2px;
    min-width: 138px;

    right: 0;

    @media (max-width: 1023px) {
        left: 0;
    }
}
.videoFrame {
    width: 100%;
    height: 100%;
  }
  .selectedTitle {
    text-decoration: underline;
  }

.thumbnail {
    height: 100%;
    width: 100%;
}

.controls {
    /* ... existing styles ... */
    transition: opacity 0.3s ease;
    opacity: 0;
    pointer-events: none;
  }
  
  .controls.visible {
    opacity: 1;
    pointer-events: auto;
  }
  
  .controls.hidden {
    opacity: 0;
    pointer-events: none;
  }
  
  /* Only show controls on hover when they're in visible state */
  .videoContainer:hover .controls.visible {
    opacity: 1;
    pointer-events: auto;
  }
  
  /* Make sure volume slider and mobile menu stay visible when open */
  .controls .volumeSliderContainer,
  .controls .controlsWrapper.showMobile {
    pointer-events: auto;
  }