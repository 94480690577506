.prevArrow {
  border: 0;
  position: absolute;
  left: -20px;
  top: 35%;
  z-index: 3;
  cursor: pointer;
 
  & svg {
    transform: rotate(180deg);
  }
  &:disabled {
    display: none;
  }
}

.nextArrow {
  border: 0;
  position: absolute;
  right: 0;
  top: 35%;
  z-index: 3;
  cursor: pointer;
  @media (max-width: 767px) {
    display: none;
    }
    &:disabled {
      display: none;
    }
}