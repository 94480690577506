.videoContainer {
  position: relative;
  width: 100%;
  max-width: 900px;
  margin: auto;
  background: black;
}

.controls {
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  padding: 0px;
  padding-top: 0;
  color: white;
  transition: all ease-in-out 200ms;
  padding-bottom: 10px;
  padding-right: 2%;
}

.hidden {
  opacity: 0;
  z-index: -1;
  transition: all ease-in-out 200ms;
}

.progressContainer {
  width: 93%;
  height: 8px;
  overflow: hidden;
}

.progressBar {
  -webkit-appearance: none;
  width: 100%;
  height: 6px;
  background: transparent;
  cursor: pointer;
  border: 0;
  padding: 0;
  overflow: hidden;
}

/* Progress bar track */
.progressBar::-webkit-slider-runnable-track {
  width: 100%;
  height: 10px;
  background: rgba(255, 255, 255, 0.1);
  border: none;
  border-radius: 0;
}

.progressBar::-moz-range-track {
  width: 100%;
  height: 10px;
  background: rgba(255, 255, 255, 0.1);
  border: none;
  border-radius: 0;
}

/* Progress bar fill */
.progressBar::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 10px;
  width: 0;
  background: #d6d6d6;
  cursor: pointer;
  border: none;
  border-radius: 0;
  box-shadow: -100vw 0 0 100vw #d6d6d6;
}

.progressBar::-moz-range-thumb {
  height: 10px;
  width: 0;
  background: #d6d6d6;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  box-shadow: -100vw 0 0 100vw #d6d6d6;
}

/* Hide focus outline */
.progressBar:focus {
  outline: none !important;
  background-color: #343434;
  box-shadow: none !important;
}

/* Optional: Add hover effect */
.progressContainer:hover .progressBar::-webkit-slider-thumb {
  height: 12px;
  margin-top: -1px;
}

.progressContainer:hover .progressBar::-moz-range-thumb {
  height: 12px;
  margin-top: -1px;
}

.controlButtons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  &.smControlButtons {
    & button {
      padding: 1px;
    }
    & .volumeAndSubtitle {
      & svg {
        width: 16px;
      }
      & .volumeSlider {
        max-width: 70px;
      }
    }
  }
  &.mdControlButtons {
    & button {
      padding: 5px;
    }
    & .volumeAndSubtitle {
      & svg {
        width: 24px;
      }
    }
  }
  &.lgControlButtons {
    & button {
      padding: 10px;
    }
    & .volumeAndSubtitle {
      & svg {
        width: 40px;
      }
    }
  }
}

.button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  padding: 5px;
  margin: 0 5px;
}

.button:hover {
  color: #00adee;
}

.volumeControl {
  display: flex;
  align-items: center;
  & button {
    padding: 0 !important;
  }
}

.volumeSlider {
  width: 100px;
  margin-left: 10px;
  padding: 0;
}

.subtitleMenu {
  position: relative;
}

.subtitleList {
  position: absolute;
  transform: translateY(-50%);
  top: -50px;
  right: 0;
  background: rgba(0, 0, 0, 0.9);
  list-style: none;
  padding: 5px;
  margin: 0;
  width: 100px;
}

.subtitleList li {
  cursor: pointer;
  padding: 5px;
}

.subtitleList li.active {
  font-weight: bold;
  color: #00adee;
}

.controlsWrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding-left: 5px;
  margin-left: 5px;
  padding-right: 10px;
}

.volumeAndSubtitle {
  display: flex;
  align-items: center;
  gap: 10px;
}

.playingIcon {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #323232;
  border-radius: 50%;
  & svg {
    width: 18px;
    & path {
      fill: #888888 !important ;
    }
  }
  &.iconSmall {
    width: 20px;
    height: 20px;
    & svg {
      width: 12px;
    }
  }
  &.iconMedium {
    width: 30px;
    height: 30px;
    & svg {
      width: 18px;
    }
  }
  &.iconLarge {
    width: 40px;
    height: 40px;
    & svg {
      width: 25px;
    }
  }
}

.playIconWrapper {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 50%;
  & .playIcon {
    border-style: solid;
    border-width: 6px 0px 6px 10px; /* sm */
    border-color: transparent transparent transparent black;
    margin-left: 2.5px;
    display: block;
  }
  &.iconMedium .playIcon {
    border-width: 9px 0px 9px 15px; /* md */
    margin-left: 3.75px;
  }
  &.iconLarge .playIcon {
    border-width: 12px 0px 12px 20px; /* lg */
    margin-left: 5px;
  }
  &.iconSmall {
    width: 20px;
    height: 20px;
    & svg {
      width: 12px;
    }
  }
  &.iconMedium {
    width: 30px;
    height: 30px;
    & svg {
      width: 18px;
    }
  }
  &.iconLarge {
    width: 40px;
    height: 40px;
    & svg {
      width: 25px;
    }
  }
}

.videoPlayControl {
  display: flex;
  align-items: center;
}

.volumeSlider {
  -webkit-appearance: none;
  width: 100%;
  height: 6px;
  background: transparent;
  cursor: pointer;
  border: 0;
  padding: 0;
  overflow: hidden;
  max-width: 100px;
}

/* Progress bar track */
.volumeSlider::-webkit-slider-runnable-track {
  width: 100%;
  height: 10px;
  background: rgba(255, 255, 255, 0.1);
  border: none;
  border-radius: 0;
}

.volumeSlider::-moz-range-track {
  width: 100%;
  height: 10px;
  background: rgba(255, 255, 255, 0.1);
  border: none;
  border-radius: 0;
}

/* Progress bar fill */
.volumeSlider::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 10px;
  width: 0;
  background: #d6d6d6;
  cursor: pointer;
  border: none;
  border-radius: 0;
  box-shadow: -100vw 0 0 100vw #d6d6d6;
}

.volumeSlider::-moz-range-thumb {
  height: 10px;
  width: 0;
  background: #d6d6d6;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  box-shadow: -100vw 0 0 100vw #d6d6d6;
}

/* Hide focus outline */
.volumeSlider:focus {
  outline: none !important;
  background-color: #343434;
  box-shadow: none !important;
}

/* Optional: Add hover effect */
.volumeControl:hover .volumeSlider::-webkit-slider-thumb {
  height: 12px;
  margin-top: -1px;
}

.volumeControl:hover .volumeSlider::-moz-range-thumb {
  height: 12px;
  margin-top: -1px;
}

.iconSmall {
  width: 20px;
  height: 20px;
}

.iconMedium {
  width: 30px;
  height: 30px;
}

.iconLarge {
  width: 40px;
  height: 40px;
}


.remainingTime{
  text-wrap: nowrap;
}