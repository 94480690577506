@import '../../styles/customMediaQueries.css';

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 44px;
  @media (max-width: 1023px) {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
}
.carouselSection {
  max-width: var(--contentMaxWidthPages);
  gap: 47px;
  margin: 0 auto;
  padding: 32px;
  width: 100%;
  padding: 32px 22px 0 22px;
  overflow: hidden;
  &:last-child {
    margin-bottom: 84px;
  }
  @media (--viewportLarge) {
    padding: 10px 60px 84px 60px;
  }
  @media (min-width: 1580px) {
    padding: 10px 104px 84px 104px;
  }
  &.wishlistCarousel {
    padding: 0;
    max-width: 1160px;
    & :global(.slick-list) {
      max-width: 1160px;
      @media (max-width: 1200px) {
        max-width: calc(100vw - 40px);
      }
    }
  }
}

.title {
  color: #fff;
  font-family: 'Sofia Pro';
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 80% */
}

.viewAll {
  cursor: pointer;
  text-align: right;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
  letter-spacing: 0.3px;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: none;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
  @media (max-width: 767px) {
    display: none;
  }
  &:hover {
    color: #ff0000;
  }
}

.hitsContainer {
  position: relative;
  max-width: 1616px;
  @media (max-width: 1640px) {
    max-width: calc(100vw - 40px);
  }
}

.prevArrow {
  border: 0;
  position: absolute;
  left: -20px;
  top: 35%;
  z-index: 3;
  cursor: pointer;
  & svg {
    transform: rotate(180deg);
  }
}

.nextArrow {
  border: 0;
  position: absolute;
  right: 0;
  top: 35%;
  z-index: 3;
  cursor: pointer;
  @media (max-width: 1023px) {
    display: none;
  }
}

.card {
  cursor: pointer;
  max-width: 269px;
  width: 100%;
  background-color: #000;
  color: #fff;
  border-radius: 10px;
  overflow: hidden;
  text-align: center;
  font-family: Arial, sans-serif;
  margin: 0 0px;
  transition: transform 0.3s ease;
  @media (max-width: 576px) {
    margin: 0 auto;
    max-width: 244px;
  }
}

.card:hover {
  /* transform: scale(1.05); */
}

.imageContainer {
  position: relative;
  border-radius: 8px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
  height: 399px;

  &.creatorImageContainer {
    border: 1.5px solid #313131;
    overflow: hidden;
    background-color: #171819;
  }
}

.cardImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;

  @media (max-width: 767px) {
    height: 361px;
  }

  &.creatorImage {
    height: 266px;
    border-radius: 8px 8px 0 0;
  }
}

.cardBody {
  padding: 18px 0 0 0px;
}

.rating {
  color: #ffd700; /* Gold for stars */

  margin-bottom: 5px;
  text-align: left;
  & svg {
    width: 12px !important;
    height: 12px !important;
  }
  & :global(.star-ratings) {
    display: flex !important;
    align-items: center;
    & > div {
      width: 12px !important;
      height: 12px !important;
      display: flex !important;
      padding: 0 !important;
      align-items: center;
      justify-content: center;
      vertical-align: super !important;
    }
  }
}

.username {
  margin-bottom: 0px;
  text-align: left;
}

.cardTitle {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 100% */
  text-align: left;
  margin: 12px 0 7px;
  padding: 0;
}

.cardDetails {
  & p {
    margin: 0;
    padding: 0;
    text-align: left;
    color: #fff;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 169.231% */
  }
}

.hitsContainer {
  position: relative;
  max-width: 1576px;
  @media (max-width: 1640px) {
    max-width: calc(100vw - 120px);
  }
  @media (max-width: 1023px) {
    max-width: calc(100vw - 40px);
  }
}

.ratings {
  text-align: left;
  & svg {
    height: 12px !important;
    width: 12px !important;
  }
}

.creatorCard {
  position: relative;
  & .removeButton {
    position: absolute;
    top: 0px;
    right: 26px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.88) 100%);
    left: 0;
    padding: 7px 11px;
    display: flex;
    justify-content: flex-end;
  }
  & .removeIcon {
    cursor: pointer;
  }
}

.smallImg {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 15px;
  position: absolute;
  bottom: 0;
  padding: 18px 24px 24px;
  left: 0;
  right: 0;
  background-color: #171819;

  img {
    object-fit: cover;
    border-radius: 8px !important;

    width: 64px;
    height: 93px;
  }
}

.smallImgPlaceholder {
  margin-top: 20px;
  & p {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 133.333% */
  }
}
