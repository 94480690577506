.root {
  position: absolute;
  z-index: var(--zIndexPopup);
  border-top: 0px solid var(--colorGrey100);
  box-shadow: var(--boxShadowPopup);
  transition: var(--transitionStyleButton);
  border-radius: 4px;
  background: var(--Slate, #171819);
}

.isClosed {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transform: translateY(-1em);
}

.isOpen {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
  transform: translateY(0em);
  transition: transform 0.5s ease;
}

.content {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding-bottom: 20px;
}

/* Styles for arrow (if arrowPosition is defined) */
.arrowTop,
.arrowBelow {
  content: ' ';
  position: absolute;
  bottom: 100%;
  height: 0;
  width: 0;
  border: solid transparent;
  pointer-events: none;
}

.arrowTop {
  border-bottom-color: #171819;
  border-width: 7px;
  margin-left: -7px;
}

.arrowBelow {
  border-bottom-color: #171819;
  border-width: 9px;
  margin-left: -9px;
}
