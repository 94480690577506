@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}

.tab {
  margin-left: 16px;

  &:first-child {
    margin-left: 0;
  }

  @media (--viewportLarge) {
    margin-left: 24px;
  }
}

.link {
  display: inline-flex;
  white-space: nowrap;

  /* Font */
  composes: textSmall from global;

  color: var(--colorWhite);
  padding-bottom: 10px;
  padding-top: 10px;
  height: 100%;
  font-weight: 300;
  /* push tabs against bottom of tab bar */
  margin-top: auto;

  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: transparent;

  transition: var(--transitionStyleButton);
  background-image: url("data:image/svg+xml;utf8,<svg width='4' height='32' viewBox='0 0 4 32' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h4v32H0z' fill='%234a4a4a' fill-rule='evenodd'/></svg>");
  background-position: calc(var(--TabNav_linkWidth) + 4px) center; /* SelectedLink's width (200px) + border thickness (4px) = 204px */

  &:hover {
    text-decoration: none;
    color: var(--colorWhite);
  }

  @media (--viewportLarge) {
    font-weight: 300;
    font-size: 21px;
    line-height: 48px;

    height: unset;
    width: var(--TabNav_linkWidth);
    border-bottom-width: 0px;
    margin-top: 0;
    margin-bottom: 0px;

    padding-top: 0px; /* Drop the text to adjust it to correct baseline */
    padding-bottom: 3px;

    &:hover {
      background-image: url("data:image/svg+xml;utf8,<svg width='4' height='32' viewBox='0 0 4 32' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h4v32H0z' fill='%234a4a4a' fill-rule='evenodd'/></svg>");
      background-position: right center;
    }
  }
}

.selectedLink {
  border-bottom-color: var(--colorBlack);
  color: var(--colorWhite);
  font-weight: 900;
  @media (--viewportLarge) {
    width: var(--TabNav_linkWidth);
    background-image: url("data:image/svg+xml,%3Csvg width='5' height='27' viewBox='0 0 5 27' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='5' height='27' fill='%23ED2124'/%3E%3C/svg%3E%0A");
    background-position: right center;

    &:hover {
      background-image: url("data:image/svg+xml;utf8,<svg width='4' height='32' viewBox='0 0 4 32' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h4v32H0z' fill='%234a4a4a' fill-rule='evenodd'/></svg>");
      background-position: right center;
    }
  }
}

.disabled {
  pointer-events: none;
  color: var(--colorGrey300);
  text-decoration: none;
}

.editWizard{

  &.done{
    &::before {
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='17' viewBox='0 0 16 17' fill='none'%3E%3Cpath d='M6.6 12.008L12.996 5.612L12.289 4.904L6.6 10.592L3.75 7.742L3.042 8.45L6.6 12.008ZM1.616 16.5C1.15533 16.5 0.771 16.346 0.463 16.038C0.155 15.73 0.000666667 15.3453 0 14.884V2.116C0 1.65533 0.154333 1.271 0.463 0.963C0.771667 0.655 1.156 0.500667 1.616 0.5H14.385C14.845 0.5 15.2293 0.654333 15.538 0.963C15.8467 1.27167 16.0007 1.656 16 2.116V14.885C16 15.345 15.846 15.7293 15.538 16.038C15.23 16.3467 14.8453 16.5007 14.384 16.5H1.616Z' fill='%2341B870'/%3E%3C/svg%3E");
      display: inline-block;
      margin-right: 15px;
      vertical-align: middle;
    }
  }
}