@import '../../styles/customMediaQueries.css';

.root {
  display: block;

  flex-shrink: 0;
  padding: 0;

  &:hover {
    text-decoration: none;
  }

  @media (--viewportMedium) {
    padding: 0 24px;
    height: 100%;

  }

  @media (--viewportLarge) {
    padding: 0 18px 0 20px;
  }

  @media (min-width: 1300px) {
    padding: 0 36px;
  }
}