.customField {
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }

  @media (--viewportMedium) {
    margin-bottom: 32px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.customRadioField {
  display: flex;
  gap: 30px;
  margin-top: 18px;
  @media (max-width: 768px) {
    gap: 18px;
    flex-direction: column;
  }
  & span{
    margin-bottom: 0;
  }
  & > span {
    flex: 1;
    position: relative;
    border-radius: 10px;
    border: 0.5px solid var(--Mid-Grey, #9d9d9d);
    background: var(--Slate, #171819);
    padding: 16px 23px;

    & input {
      height: auto;
      width: auto;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
    & span {
      color: var(--colorWhite);
    }
    & label {
      padding: 0;
      &:hover {
        color: var(--colorWhite);
      }
    }
  }
}

.customRadioFieldRefundPolicy{
  composes: customRadioField;
  flex-direction: column;
  gap: 19px;
  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
}

.label{
  padding: 0;
}

.subtitleLabel {
  display: flex;
  gap: 8px;
  align-items: center;
}